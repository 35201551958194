const schainColumns = [
  {
    key: 'DateStats',
    label: 'Date',
    show: true,
    headerTitle: 'Date',
    sortable: true,
    thStyle: 'width:90px',
    summed: false,
  },
  {
    key: 'PublisherId',
    label: 'Publisher ID',
    show: true,
    headerTitle: 'Publisher ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'PublisherName',
    label: 'Publisher Name',
    show: true,
    headerTitle: 'Publisher Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'RtbzoneId',
    label: 'RTB Zone ID',
    show: true,
    headerTitle: 'RTB Zone ID',
    sortable: true,
    thStyle: 'width:105px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'RtbzoneName',
    label: 'RTB Zone Name',
    show: true,
    headerTitle: 'RTB Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'Asi',
    label: 'ASI',
    show: true,
    headerTitle: 'ASI',
    sortable: true,
    tdClass: 'text-left',
    summed: false,
  },
  {
    key: 'Sid',
    label: 'SID',
    show: true,
    headerTitle: 'SID',
    sortable: true,
    tdClass: 'text-left',
    summed: false,
  },
  {
    key: 'PubId',
    label: 'Pub ID',
    show: true,
    headerTitle: 'Pub ID',
    sortable: true,
    tdClass: 'text-left',
    summed: false,
  },
  {
    key: 'Error',
    label: 'Error',
    show: true,
    headerTitle: 'Error',
    sortable: true,
    thStyle: 'width: 140px',
    class: 'text-center',
    summed: false,
  },
  {
    key: 'Errors',
    label: 'Errors',
    show: true,
    headerTitle: 'Errors',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
]
export default schainColumns
